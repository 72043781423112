import React from "react";
import MainButton from "../MainButton/MainButton";
import "./Slider.css";
import config from "../../config";

const Slider = ({page,title}) => {
    function pageSider(page) {
        switch (page) {
            case 'home':
                return(
                    // <div className="carousel-item active">
                    //     {/* <img src={TestImage} className="d-block w-100" alt="..." /> */}
                    //     <img
                    //         src={`${config.urlApi}assets/sliders/main-page-banner.png`}
                    //         className="d-block w-100 industry__banner-lg"
                    //         alt="..."
                    //     />
                    //     <img
                    //         src={`${config.urlApi}assets/sliders/main-page-banner-sm.png`}
                    //         className="d-block w-100 industry__banner-sm"
                    //         alt="..."
                    //     />
                    //     <div className="carousel-caption d-none d-md-block text-start">
                    //         <span className="flag mt-5">RIE CON GANAS</span>
                    //         <h1 className="white-color">
                    //             En DIVUM siempre sabrás el costo de tus tratamientos
                    //         </h1>
                    //         <MainButton text="AGENDAR AHORA" url={'/contacto'} color={'w'}/>
                    //     </div>
                    // </div>
                    <img
                        src={`${config.urlApi}assets/sliders/certificado_medico.png`}
                        className="d-block w-100 industry__banner-lg"
                        alt="..."
                    />
                )
                break;
                
            case 'treatments':
                return(
                    <div className="carousel-item active">
                        {/* <img src={TestImage} className="d-block w-100" alt="..." /> */}
                        <img
                            src={`${config.urlApi}assets/sliders/treatments-banner.png`}
                            className="d-block w-100 industry__banner-lg"
                            alt="..."
                        />
                        <img
                            src={`${config.urlApi}assets/sliders/treatments-banner-sm.png`}
                            className="d-block w-100 industry__banner-sm"
                            alt="..."
                        />
                        <div className="carousel-caption d-none d-md-block text-start">
                            <span className="flag mt-5">TODO EN EL MISMO LUGAR</span>
                            <h1 className="white-color">
                                TRATAMIENTOS
                            </h1>
                            <p className="white-color fw-light carousel__treatmentsDec">En <span className="fw-bold">DIVUM</span> queremos verte feliz, por eso ofrecemos una variedad de tratamientos que se adaptan perfectamente a tus necesidades, estilo de vida y presupuesto.</p>
                            <MainButton text="AGENDAR AHORA" url={'/contacto'} color={'w'}/>
                        </div>
                    </div>
                )
                break;
            
            case 'treatment-detail':
                return(
                    <div className="carousel-item active">
                        <img
                            src={config.urlApi + 'assets/Specific/limpieza-dental.png'}
                            className="d-block w-100 industry__banner-lg"
                            alt='Tratamientos dentales'
                        />
                        <img
                            src={config.urlApi + 'assets/Specific/limpieza-dental-sm.png'}
                            className="d-block w-100 industry__banner-sm"
                            alt='Tratamientos dentales'
                        />
                        <div className="carousel-caption d-none d-md-block text-start">
                            <span className="flag mt-5">#TRATMIENTOS</span>
                            <h1 className="white-color">
                                TRATAMIENTOS DENTALES
                            </h1>
                            <p className="white-color fw-light carousel__treatmentsDec">Dientes limpios, expresiones seguras.</p>
                            <MainButton text="AGENDAR AHORA" url={'/contacto'} color={'w'}/>
                        </div>
                    </div>
                )
                break;

            case 'location':
                return(
                    <div className="carousel-item active">
                        {/* <img src={TestImage} className="d-block w-100" alt="..." /> */}
                        <img
                            src={`${config.urlApi}assets/sliders/location-banner.png`}
                            className="d-block w-100 industry__banner-lg"
                            alt="..."
                        />
                        <img
                            src={`${config.urlApi}assets/sliders/location-banner-sm.png`}
                            className="d-block w-100 industry__banner-sm"
                            alt="..."
                        />
                        <div className="carousel-caption d-none d-md-block text-start">
                            {/* <span className="flag mt-5"><SlLocationPin className="fs-5" /></span> */}
                            <h1 className="white-color">
                                UBICACIÓN
                            </h1>
                            <p className="white-color fw-light carousel__treatmentsDec">Nos encontramos cerca del metro Olimpica o Plaza Aragón.</p>
                            {/* <MainButton text="AGENDAR AHORA" url={'/contacto'} color={'w'}/> */}
                        </div>
                    </div>
                )
                break;
                
            case 'contact':
                return(
                    <div className="carousel-item active">
                        {/* <img src={TestImage} className="d-block w-100" alt="..." /> */}
                        <img
                            src={`${config.urlApi}assets/sliders/contact-banner.png`}
                            className="d-block w-100 industry__banner-lg"
                            alt="..."
                        />
                        <img
                            src={`${config.urlApi}assets/sliders/contact-banner-sm.png`}
                            className="d-block w-100 industry__banner-sm"
                            alt="..."
                        />
                        <div className="carousel-caption d-none d-md-block text-start">
                            <span className="flag mt-5">SIEMPRE CERCA DE TÍ</span>
                            <h1 className="white-color">
                                CONTÁCTANOS
                            </h1>
                            <p className="white-color fw-light carousel__treatmentsDec m-0">Llámanos al <span className="fw-bold">55 4336 0298</span> y consigue</p>
                            <p className="fw-bold white-color">¡La sonrisa que siempre has soñado!</p>
                            <MainButton text="CONTACTAR AHORA" url={'/contacto'} color={'w'}/>
                        </div>
                    </div>
                )
                break;
            
            case 'divum':
                return(
                    <div className="carousel-item active">
                        {/* <img src={TestImage} className="d-block w-100" alt="..." /> */}
                        <img
                            src={`${config.urlApi}assets/sliders/divum-banner.png`}
                            className="d-block w-100 industry__banner-lg"
                            alt="..."
                        />
                        <img
                            src={`${config.urlApi}assets/sliders/divum-banner-sm.png`}
                            className="d-block w-100 industry__banner-sm"
                            alt="..."
                        />
                        <div className="carousel-caption d-none d-md-block text-start">
                            <span className="flag mt-5">#clinicadental</span>
                            <h1 className="white-color">
                                Clínica DIVUM
                            </h1>
                            <p className="white-color fw-light carousel__treatmentsDec m-0">Somos la <span className="fw-bold">clínica dental</span> perfecta para ti.</p>
                            <MainButton text="CONTACTAR AHORA" url={'/contacto'} color={'w'}/>
                        </div>
                    </div>
                )
                break;
                
            default:
                return(
                    <div className="carousel-item active">
                        {/* <img src={TestImage} className="d-block w-100" alt="..." /> */}
                        <img
                            src="https://via.placeholder.com/1500x450"
                            className="d-block w-100 industry__banner"
                            alt="..."
                        />
                        <div className="carousel-caption d-none d-md-block">
                            <h1 className="title-bold text-uppercase display-3">
                            Default
                            </h1>
                            <p className="text-uppercase mt-3">
                            TENEMOS LA SOLUCIÓN PARA REDUCIR LA CONTAMINACIÓN DE TUS
                            INSTALACIONES
                            </p>
                        </div>
                    </div>
                )
                break;
        }
    }

  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        {pageSider(page)}
      </div>
    </div>
  );
};

export default Slider;
