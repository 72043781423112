import React from 'react';
import './Reviews.css';
import OwlCarousel from "react-owl-carousel";
import Avatar from '../../assets/images/avatar.png'
import AvatarWoman from '../../assets/images/avatar-woman.png'
import { AiTwotoneStar } from 'react-icons/ai';

const Reviews = () => {

    const state = {
        responsive: {
          0: {
            items: 2,
            center: true,
            margin: 10,
          },
          576: {
            items: 2,
            center: true,
            margin: 30
          },
          768: {
            items: 3,
            center: true,
            margin: 30
          },
          992: {
            items: 3,
            center: true,
            margin: 35
          },
          1200: {
            items: 5,
            center: true,
            margin: 35
          },
        },
      };

  return (
    <div className='reviews py-5 text-center'>
        <h2 className='blue-color fs-1'>Lo que nuestros pacientes dicen</h2>
        <p className='fw-light mt-4'>Te compartimos algunas testimos de lo que nuestros pacientes han recibido al acudir a DIVUM</p>
        
        <OwlCarousel
          className="owl-theme my-5"
          items="1"
          loop
          autoplay={true}
          animateIn={true}
          responsive={state.responsive}
          center={true}

        >
          {/* {products.map((product) => ( */}
            <div className="item">
              <div className='shadow reviews__card p-3 text-center'>
                <img src={Avatar} alt="Avatar" className='reviews__avatar' />
                <h4 className='p-0 mt-3 blue-color'>Juan Omar</h4>
                <p className='pt-2'>“Por fin pude arreglar mis dientes de manera fácil, a un precio accesible y con los más profesionales”</p>
                <div className='d-flex justify-content-center'>
                    <AiTwotoneStar className='reviews__star fs-2' />
                    <AiTwotoneStar className='reviews__star fs-2' />
                    <AiTwotoneStar className='reviews__star fs-2' />
                    <AiTwotoneStar className='reviews__star fs-2' />
                    <AiTwotoneStar className='reviews__star fs-2' />
                </div>
              </div>
            </div>
            <div className="item">
              <div className='shadow reviews__card p-3 text-center'>
                <img src={AvatarWoman} alt="Avatar" className='reviews__avatar' />
                <h4 className='p-0 mt-3 blue-color'>Juan Omar</h4>
                <p className='pt-2'>“Por fin pude arreglar mis dientes de manera fácil, a un precio accesible y con los más profesionales”</p>
                <div className='d-flex justify-content-center'>
                    <AiTwotoneStar className='reviews__star fs-2' />
                    <AiTwotoneStar className='reviews__star fs-2' />
                    <AiTwotoneStar className='reviews__star fs-2' />
                    <AiTwotoneStar className='reviews__star fs-2' />
                    <AiTwotoneStar className='reviews__star fs-2' />
                </div>
              </div>
            </div>
          {/* ))} */}
        </OwlCarousel>
    </div>
  )
}

export default Reviews;