import React from 'react';
import './Highlights.css';
import Integral from '../../assets/icons/integral.svg';
import Payment from '../../assets/icons/payment-flexibility.svg';
import Professional from '../../assets/icons/professional.svg';
import Warranty from '../../assets/icons/warranty.svg';

const Highlights = () => {
  return (
    <div className='row gy-3 highlights d-flex justify-content-around'>
        <div className='col-6 col-xl-2 col-lg-2 col-md-6 col-sm-6 text-center' data-aos="fade-up" data-aos-delay="300">
            <img src={Professional} alt='Profesionalismo' className='img-fluid'/>
            <p className='fs-5 mt-1'>Profesionales</p>
        </div>
        <div className='col-6 col-xl-2 col-lg-2 col-md-6 col-sm-6 text-center' data-aos="fade-up" data-aos-delay="200">
            <img src={Integral} alt='Servicio integral' className='img-fluid'/>
            <p className='fs-5 mt-1'>Servicio integral</p>
        </div>
        <div className='col-6 col-xl-2 col-lg-2 col-md-6 col-sm-6 text-center' data-aos="fade-up" data-aos-delay="100">
            <img src={Payment} alt='Facilidades de pago' className='img-fluid'/>
            <p className='fs-5 mt-1'>Facilidades de pago</p>
        </div>
        <div className='col-6 col-xl-2 col-lg-2 col-md-6 col-sm-6 text-center' data-aos="fade-up">
            <img src={Warranty} alt='Clientes satisfechos' className='img-fluid'/>
            <p className='fs-5 mt-1'>Clientes satisfechos</p>
        </div>
    </div>
  )
}

export default Highlights;