// import { async } from "@firebase/util";
import React, { useEffect, useState } from "react";
import contactFormServices from "../firebaseServices/contactForm.services";

const ContactMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    getFormMessages();
  }, []);

  const getFormMessages = async () => {
    const messages = await contactFormServices.getAllForms();
    setMessages(messages.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

//   const getFormMessage = async (id) => {
//     const message = await contactFormServices.getForm(id);
//     return message;
//   };

  const dateFormatted = (date) => {
    return new Date(date).toLocaleDateString("es-MX");
  };

  const handleDelete = async(id) => {
    contactFormServices.deleteContactForm(id);
    getFormMessages();
  }

  return (
    <div>
      <pre>{JSON.stringify(messages, undefined, 2)}</pre>
      {messages.map(
        (message) =>
          message.seen === false && (
            <div key={message.id}>
              <p>{message.id}</p>
              <p>{dateFormatted(message.date)}</p>
              {/* <button onClick={}>Editar</button> */}
              <button onClick={(e) => handleDelete(message.id)}>Eliminar</button>
            </div>
          )
      )}
    </div>
  );
};

export default ContactMessages;
