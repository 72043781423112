import React from 'react';
import MainButton from '../MainButton/MainButton';
import './Treatments.css';
import Esteticos from '../../assets/images/estetica.png';
import Clinicos from '../../assets/images/clinica.png';

const Treatments = () => {
  return (
    <div className='container tratments my-5 overflow-hidden'>
        <h2 className='blue-color text-center fs-1'>Tratamientos</h2>
        <div className='row d-flex align-items-center my-5'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 my-3' data-aos="fade-right">
              <span className='flag'>ESTÉTICOS</span>
              <h2 className='black-color my-4'>Sonrisa perfecta con blanqueamiento, rediseño o alineación de tu sonrisa</h2>
              <p className='fw-light my-4'>En DIVUM sabemos que tu sonrisa es muy importante, y es por que nos encargamos de tratarlos hasta el más minimo detalle y mejorar su apariencia física.</p>
              <MainButton text='VER TODO' url='/tratamientos'/>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 my-3 text-center p-0'>
              <img src={Esteticos} alt='Tratamientos estéticos' className='img-fluid shadow'/>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 my-3 text-center p-0 tratments__img'>
              <img src={Clinicos} alt='Tratamientos estéticos' className='img-fluid shadow'/>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 my-3' data-aos="fade-left">
              <span className='flag'>CLÍNICOS</span>
              <h2 className='black-color my-4'>Sonrisa saludable desde resinas, hasta coronas e implantes</h2>
              <p className='fw-light my-4'>En DIVUM atendemos desde la más minima molesta, para nosotros lo más importante es proteger tus dientes.</p>
              <MainButton text='VER TODO' url='/tratamientos'/>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 my-3 text-center p-0 tratments__imgLg'>
              <img src={Clinicos} alt='Tratamientos estéticos' className='img-fluid shadow'/>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 my-3' data-aos="fade-right">
              <span className='flag'>ODONTOPEDIATRÍA</span>
              <h2 className='black-color my-4'>Protegemos la sonrisa de los más pequeños</h2>
              <p className='fw-light my-4'>En DIVUM cuidamos de los más pequeños mediante diversas técnicas para brindarles confianza a la hora de realizar los procedimientos.</p>
              <MainButton text='VER TODO' url='/tratamientos'/>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 text-center p-0 '>
              <img src={Esteticos} alt='Tratamientos estéticos' className='img-fluid shadow'/>
            </div>
        </div>
    </div>
  )
}

export default Treatments;