import { db } from "../firebase-config";

import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

const contactFormCollection = collection(db, "contactForms");

class ContactFormServices {
  addContacForm = (dataForm) => {
    return addDoc(contactFormCollection, dataForm);
  };

  updateContactForm = (id, updateDataForm) => {
    const contactFormDoc = doc(db, "contactForms", id);
    return updateDoc(contactFormDoc, updateDataForm);
  };

  deleteContactForm = (id) => {
    const contactFormDoc = doc(db, "contactForms", id);
    return deleteDoc(contactFormDoc);
  };

  getAllForms = () => {
    return getDocs(contactFormCollection);
  };

  getForm = (id) => {
    const contactFormDoc = doc(db, "contactForms", id);
    return getDoc(contactFormDoc);
  };
}

export default new ContactFormServices();
