import React from "react";
import config from "../../config";
import MainButton from "../MainButton/MainButton";
import "./BlogSlider.css";

const BlogSlider = ({sliders}) => {

  return (
    <div className="blog__slider mt-5 shadow">
      <div
        id="carouselExampleCaptions"
        className="carousel"
        data-bs-ride="carousel"
      >
      
        <div className="carousel-inner">
          {sliders?.map((slide, index) => {
            if(index === 0) {
              return (
                <div className="carousel-item active" key={'slide'+slide.id_post}>
                  <img
                    src={config.urlApi+'assets/posts/'+slide.image}
                    className="d-block w-100"
                    alt={slide.title}
                  />
                  <div className="carousel-caption d-md-block text-center p-0">
                    <p>{slide.category}</p>
                    <h3>{slide.title}</h3>
                    <p>{slide.description}</p>
                    <MainButton text="VER MÁS" url={"/blog/"+slide.url} color={"w"} />
                  </div>
                </div>
              )
            } else {
              return (
                <div className="carousel-item" key={'slide'+slide.id_post}>
                  <img
                    src={config.urlApi+'assets/posts/'+slide.image}
                    className="d-block w-100"
                    alt={slide.title}
                  />
                  <div className="carousel-caption d-md-block text-center p-0">
                    <p>{slide.category}</p>
                    <h3>{slide.title}</h3>
                    <p>{slide.description}</p>
                    <MainButton text="VER MÁS" url={"/blog/"+slide.url} color={"w"} />
                  </div>
                </div>
              )
            }
          })}

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Anterior</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogSlider;
