import $ from "jquery";
import config from "../config";
const URL_API = config.urlApi + "controller/getPost.php";

export default function getPost(formData) {
  return $.ajax({
    url: URL_API,
    type: "POST",
    data: formData,
    contentType: false,
    processData: false,
  });
}
