import React, { useEffect, useState } from 'react';
import './TeamCards.css';
import config from '../../config';
// import SectionBanner from '../../components/sectionBanner/SectionBanner';
import itzelTeam from '../../assets/images/team/itzel-rivera.png';
import alexaTeam from '../../assets/images/team/alexa-suarez.png';
import charlotteTeam from '../../assets/images/team/charlotte-flores.png';
import griselTeam from '../../assets/images/team/grisel-salgado.png';
import marcoTeam from '../../assets/images/team/marco-campuzano.png';

const TeamCards = () => {

    const[members, setMembers] = useState([]);

    const experts = [
        {
            id: 1,
            name: 'Itzel Rivera',
            description: 'Beautyness Expert',
            img: itzelTeam,
            // img: 'https://via.placeholder.com/350',
            words: 'Resalta tu belleza natural e irradia confianza con nuestros tratamientos de belleza transformadores.'
        },
        {
            id: 2,
            name: 'Alexa Suárez',
            description: 'Beautyness Expert',
            img: alexaTeam,
            words: 'Descubre el poder del cuidado personal y abraza tu belleza interior.'
        },
        {
            id: 3,
            name: 'Dra. Charlotte Flores',
            description: 'Beautyness Expert',
            img: charlotteTeam,
            words: 'Libera tu verdadero potencial de belleza con nuestros servicios diseñados específicamente para tí.'
        },
        {
            id: 4,
            name: 'Grisel Salgado',
            description: 'Beautyness Expert',
            img: griselTeam,
            words: 'Experimenta el arte de la belleza con ayuda de nuestros expertos.'
        },
        {
            id: 5,
            name: 'Marco Campuzano',
            description: 'Recepcionista',
            img: marcoTeam,
            words: 'Disfruta de una experiencia inolvidable.'
        },
    ]

  return ((!experts) ?
    <div className="d-flex justify-content-center">
        <div className="spinner-grow text-info" role="status">
            <span className="sr-only">Cargando...</span>
        </div>
    </div>
    :
    <div className='teamCard__container mt-5'>
        <div className='row gx-5'>
        {
            experts.map((expert) => (
            <div key={expert.id} className='col-6 col-xl-4 col-lg-4 col-md-6 col-sm-6 text-center' data-aos-delay="100">
                <img className='img-fluid shadow' src={expert.img} alt={expert.name}/>
                <h3 className='mt-2 blue-color'>{expert.name}</h3>
                <p className='text-uppercase'>{expert.description}</p>
            </div>
        ))}
        </div>
    </div>
  )
}

export default TeamCards;