import { useEffect } from "react";
import { Routes, Route,useLocation } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import AllTreatments from "./pages/AllTreatments";
import TreatmentDetail from "./pages/TreatmentDetail";
import LocationDivum from "./pages/LocationDivum";
import ContactUs from "./pages/ContactUs";
import ContactMessages from "./pages/ContactMessages";
import Team from "./pages/Team";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import PostEditor from "./pages/PostEditor";
import Post from "./pages/Post";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  },[location.pathname]);
  
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tratamientos" element={<AllTreatments />} />
        <Route path="/tratamientos/:tratamiento" element={<TreatmentDetail />} />
        <Route path="/ubicacion" element={<LocationDivum />} />
        <Route path="/mensajes" element={<ContactMessages />} />
        <Route path="/contacto" element={<ContactUs />} />
        <Route path="/equipo" element={<Team />} />
        <Route path="/nosotros" element={<AboutUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:url" element={<Post />} />
        <Route path="/editor" element={<PostEditor />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
