import React, { useEffect } from 'react';
import Compromise from '../components/Compromise/Compromise';
import Location from '../components/Location/Location';
import '../components/Location/Location.css';
import Slider from '../components/Slider/Slider';

const LocationDivum = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  },[]);

  return (
    <div className='location'>
        <Slider page='location' title='Ubicación'/>
        <Location />
        <Compromise />
    </div>
  )
}

export default LocationDivum;