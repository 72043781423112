import $ from "jquery";
import config from "../config";
const URL_API = config.urlApi + "controller/addPost.php";

export default function addPost(values) {
  return $.ajax({
    url: URL_API,
    type: "POST",
    data: values,
    contentType: false,
    processData: false,
  });
}
