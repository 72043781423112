import React from 'react';
import './Compromise.css';
import { GrCertificate } from 'react-icons/gr';
import { FaHandshake } from 'react-icons/fa';
import { BsArrowsMove } from 'react-icons/bs';
import { BsShieldFillCheck } from 'react-icons/bs';

const Compromise = () => {
  return (
    <div className='compromise container shadow py-3 mb-5 mt-3'>
        <div className='col-12 text-center'>
            <h2 className='blue-color fs-1'>Compromiso DIVUM</h2>
            <hr className='hr-blue my-4'/>
            <p>Queremos consolidar una clínica integral, comprometida con el bienestar y armonía facial del paciente.</p>
        </div>
        <div className='row my-5 gy-4'>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
                <div className='text-center'>
                    <GrCertificate className='fs-1 compromise__icon'/>
                    <h4 className='mt-3 fw-light'>Profesionalismo</h4>
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
                <div className='text-center'>
                    <FaHandshake className='fs-1 compromise__icon'/>
                    <h4 className='mt-3 fw-light'>Facilidades de pago</h4>
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
                <div className='text-center'>
                    <BsArrowsMove className='fs-1 compromise__icon'/>
                    <h4 className='mt-3 fw-light'>Flexibilidad</h4>
                </div>
            </div>
            <div className='col-xl-3 col-lg-3 col-md-6 col-sm-12'>
                <div className='text-center'>
                    <BsShieldFillCheck className='fs-1 compromise__icon'/>
                    <h4 className='mt-3 fw-light'>Garantía</h4>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Compromise;