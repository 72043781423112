import React, { useState, useEffect } from 'react';
import '../components/AllTreatments/AllTreatments.css';
import Slider from '../components/Slider/Slider';
import MainButton from '../components/MainButton/MainButton';
import getTreatments from '../services/getTreatments';
import config from '../config';
import { Link } from 'react-router-dom';
// import { AiOutlineSortAscending } from 'react-icons/ai';
// import { AiOutlineSortDescending } from 'react-icons/ai';

const AllTreatments = () => {
    const[allTreatments, setAllTreatments] = useState([]);
    const[order, setOrder] = useState('');
    
    useEffect(() => {
        window.scrollTo(0,0)
    },[]);

    useEffect(() => {
        getAllTreatments(order);
    }, [order]);

    function getAllTreatments(order) {

        let formData = new FormData();
        formData.append('order', order);

        getTreatments(formData).then((response) => {
            let _response = JSON.parse(response);
            if(_response.response === 'success') {
                setAllTreatments(_response.treatments);
            }
        });
    }
  return !allTreatments ? (
    <div className='text-center'> 
        <div className="spinner-grow text-info" role="status">
            <span className="sr-only">Loading...</span>
        </div>
    </div>
    )
    :
    (
    <div className='allTreatments white-background'>
        <Slider page={'treatments'}/>
        <div className='container allTreatments__cardsContainer pb-5'>
            <div className='row gx-xl-5 gx-lg-5 gx-md-5 gx-sm-0'>
                <div className='col-12'>
                    <div className='allTreatments__sort d-flex justify-content-end align-items-center mt-3'>
                        <select onChange={e => (e.target.value)&& setOrder(e.target.value)}>
                            <option defaultValue value="">Ordenar por:</option>
                            <option value="asc">A-Z</option>
                            <option value="desc">Z-A</option>
                        </select>

                    </div>
                </div>
                {allTreatments.length >= 1 && 
                    allTreatments.map((treatment) => (
                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-6 mt-5' key={treatment.id_treatment}>
                            <div className='allTreatments__card' data-aos="fade-up" data-aos-delay={(treatment.id_treatment % 2 === 0) ? 100 : 150}>
                                <Link to={"/tratamientos/"+treatment.link} >
                                    <img src={config.urlApi+'assets/Treatments/'+treatment.image} className='card-img-top allTreatments__cardImg shadow' alt={'Tratamiento de '+treatment.title}/>
                                </Link>
                                <Link to={"/tratamientos/"+treatment.link} >
                                {/* <Link to={"/tratamientos/limpieza-dental"} > */}
                                    <h4 className='p-0 mt-3 blue-color'>{treatment.title}</h4>
                                </Link>
                                <p>{treatment.description}</p>
                                {/* <MainButton  text={'VER TRATAMIENTO'} url={"/tratamientos/limpieza-dental"}/> */}
                                <MainButton  text={'VER TRATAMIENTO'} url={"/tratamientos/"+treatment.link}/>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    </div>
  )
}

export default AllTreatments;