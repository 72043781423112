import React, { useState, useEffect } from "react";
import "../components/PostEditor/PostEditor.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import getCategories from "../services/getCategories";
import Swal from "sweetalert2";
import addPost from "../services/addPost";

const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{ size: [] }],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'},{'indent': '+1'}],
        ['link'],
        ['clean']
      ],
};

const PostEditor = () => {

  const [value, setValue] = useState();
  const [title, setTitle] = useState("Título de la noticia");
  const [foreword, setForeword] = useState("Prefacio de la noticia (corto)");
  const [categories, setCategories] = useState([]);
  const [author, setAuthor] = useState("Autor de la noticia");
  const [categoriesSelected, setCategoriesSelected] = useState("");
  const [slug, setSlug] = useState("");
  const [image, setImage] = useState("https://via.placeholder.com/1200x600");

  useEffect(() => {
    getAllCategories();
  },[]);

  function getAllCategories() {
    const formData = new FormData();

    formData.append("area", "dental");

    getCategories(formData).then((response) => {
      let _response = JSON.parse(response);
      console.log("Categorias =>",_response);
      if (_response.response === "success") {
        setCategories(_response.categories);
      }
    });
  }

  function handleCategories(e) {
    e.preventDefault();
    setCategoriesSelected(e.target.value);
  }

  function handleSlug(e) {
    e.preventDefault();
    setTitle(e.target.value);
    setSlug(e.target.value.replace(/\s+/g, '-').replace(/[^\w-]/g, '').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase());
  }

  function post(e) {
    e.preventDefault();
    
    if(title === 'Título de la noticia' || foreword === 'Prefacio de la noticia (corto)' || categoriesSelected.length === 0 || !value) {
      Swal.fire({
        title: "Campos incompletos",
        text: "Por favor asegurate de llenar todos los campos",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      document.querySelector('#btn-post').disabled = true;   

      var formData = new FormData();

      formData.append('title', title);
      formData.append('foreword', foreword);
      formData.append('categories', categoriesSelected);
      formData.append('content', value);
      formData.append('author', author);
      formData.append('url', slug);
      formData.append('image', image);
      
      addPost(formData).then((response) => {
        let _response = JSON.parse(response);
        console.log("Post =>",_response);
        if (_response.response === "success") {
          Swal.fire({
            title: "Noticia publicada",
            text: "La noticia se ha publicado correctamente",
            icon: "success",
            showConfirmButton: false,
            timer: 3000,
          });

          setTimeout(() => {
            setTitle("Título de la noticia");
            setForeword("Prefacio de la noticia (corto)");
            setAuthor("Autor de la noticia");
            setCategoriesSelected("");
            setImage("https://via.placeholder.com/1200x600");
            document.querySelector('#post-form').reset();
            setValue("");
            setSlug("");
            document.querySelector('#btn-post').disabled = false; 
          }, 3000);
        } else {
          Swal.fire({
            title: "Error",
            text: "Ha ocurrido un error al publicar la noticia, intentalo de nuevo",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
          });

          document.querySelector('#btn-post').disabled = false; 
        }
      });

    }
  }

  return (
    <div className="post__editor container-fluid py-5">
      <h1 className="text-center mb-4">Editor de Noticias</h1>
      <div className="row">
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 post__editor-editor p-3">
            <form id="post-form" className="mt-3" onSubmit={(e) => post(e)}>
              <label htmlFor="title" className="form-label">Titulo de la Noticia</label>
              <input type="text" className="form-control" placeholder="Titulo de la Noticia" onChange={(e) => handleSlug(e)}/>
              
              <label htmlFor="slug" className="form-label">Slug (ruta de la url)</label>
              <p className="text-muted fs-6 fw-light">¡Recuerda que al modificar este texto no debes utilizar acentos!</p>
              <input type="text" className="form-control" value={slug} placeholder="Slug" onChange={(e) => handleSlug(e)}/>

              <label htmlFor="prefacio" className="form-label">Prefacio</label>
              <textarea className="form-control" placeholder="Prefacio de la Noticia" onChange={(e) => setForeword(e.target.value)}/>
              
              <label htmlFor="author" className="form-label">Autor</label>
              <input type="text" className="form-control" placeholder="Autor de la Noticia" onChange={(e) => setAuthor(e.target.value)}/>
              
              <label htmlFor="image" className="form-label mt-3">Imagen de la Noticia</label>
              <input type="file" placeholder="Imagen de la Noticia" className="form-control" accept="image/png, image/jpeg" onChange={(e) => setImage(e.target.files[0])} />

              <label htmlFor="image" className="form-label mt-3">Categoría de la Noticia</label>
              <select id="categories" className="form-control" onChange={(e) => handleCategories(e)}>
                {categories?.map((category) => (
                  <option value={category.id_category} key={category.name}>{category.name}</option>
                ))}
              </select>

              <ReactQuill className="mt-5" theme="snow" value={value} onChange={setValue} modules={modules} placeholder={'Escribe tu noticia aquí...'} />

              <div className="mainButton__blue mt-5 text-center">
                <button className="cta" type="submit" id="btn-post">
                  <span className='black-color' >Publicar</span>
                  <svg viewBox="0 0 13 10" width="15px" stroke='#00111A'>
                    <path d="M1,5 L11,5"></path>
                    <polyline points="8 1 12 5 8 9"></polyline>
                  </svg>
                </button>
              </div>

            </form>
        </div>
        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 post__editor-preview p-3" >
            <h1>{title}</h1>
            <img className="img-fluid post__editor-img shadow" src={(image !== 'https://via.placeholder.com/1200x600')? URL.createObjectURL(image) : image} alt="imagen" />
            <p className="mt-3">{foreword}</p>
            <div className="mt-3 text-justify" dangerouslySetInnerHTML={{ __html: value}}></div>
        </div>
      </div>
    </div>
  );
};

export default PostEditor;
