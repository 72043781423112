import React from 'react';
import '../components/Team/Team.css';
import TeamIcon from '../assets/icons/team-icon.svg';
import TeamCards from '../components/TeamCards/TeamCards';
import BannerPurple from '../components/BannerPurple/BannerPurple';

const Team = () => { 

  return (
    <div className='white-background team__main'>
        <div className='team container py-5'>
            <div className='row d-flex align-items-center pb-5'>
                <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12 order-0 order-xl-0 order-lg-0 order-md-0 order-sm-1'>
                    <h2 className='black-color'>Conoce a nuestro equipo de <i className='blue-color font-italic'>expertos</i></h2>
                    <p className='mt-4'>El equipo de dentistas de DIVUM está altamente calificado y capacitado para brindarte el mejor servicio y experiencia durante tu vista.</p>
                </div>
                <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 text-center order-1 order-xl-1 order-lg-1 order-md-1 order-sm-0'>
                    <img src={TeamIcon} className='img-fluid team__icon' alt='imagen de equipo DIVUM' />
                </div>
            </div>
            <TeamCards />
        </div>
        <BannerPurple />
    </div>
  )
}

export default Team;