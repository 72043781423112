import React from 'react';
import '../components/AboutUs/AboutUs.css';
import Slider from '../components/Slider/Slider';
import { MdArrowForwardIos } from 'react-icons/md';
import config from '../config';
import BlueButton from '../components/BlueButton/BlueButton';
import Facilities from '../components/Facilities/Facilities';
import Reviews from '../components/Reviews/Reviews';
import KPI from '../components/KPI/KPI';

const AboutUs = () => {

    const services1 = [
        {id: 1, title: 'Coronas Dentales'},
        {id: 2, title: 'Endodoncia'},
        {id: 3, title: 'Guarda Dental'},
        {id: 4, title: 'Limpieza Dental'},
    ]
    
    const services2 = [
        {id: 4, title: 'Odontopediatría'},
        {id: 5, title: 'Periodoncia'},
        {id: 6, title: 'Puentes Dentales'},
        {id: 7, title: 'Resina Dental'},
    ]
    
  return (
    <div className='aboutUs white-background'>
        <Slider page={'divum'}/>
        <section className='container py-5'>
            <div className='row'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5' data-aos="fade-up">
                    <h2 className='text-center'>¿Quiénes somos?</h2>
                    <hr className='separator footer__hr my-4'></hr>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                    <h5>DIVUM es la clínica dental perfecta para ti.</h5>
                    <p className='text-justify'>Honestidad, transparencia y profesionalismo son los principales valores que nos caracterizan. Nos esmeramos en ofrecer la mejor atención, siempre amables y cuidadosos, pero sobre todo, ofrecemos una atención personalizada, porque sabemos que las necesidades de cada uno de nuestros pacientes son diferentes.</p>
                    <div className='row'>
                        <div className='col-6'>
                            <ul>
                                {services1.map((service) => (
                                    <div key={service.id} className='mt-4' data-aos="fade-up">
                                        <li>
                                            <MdArrowForwardIos className='fs-3'/>
                                            <span>{service.title}</span>
                                        </li>
                                    </div>
                                ))}
                            </ul>
                        </div>
                        <div className='col-6'>
                            <ul>
                                {services2.map((service) => (
                                    <div key={service.id} className='mt-4' data-aos="fade-up">
                                        <li>
                                            <MdArrowForwardIos className='fs-3'/>
                                            <span>{service.title}</span>
                                        </li>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='aboutUs__purpleCard shadow' data-aos="fade-up">
                        <h3 className='white-color mb-4'>Agenda tu primer cita GRATIS</h3>
                        <BlueButton text={'Agendar Cita'} url={'/contacto'} color={'w'} />
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 text-center'>
                    <img src={`${config.urlApi}assets/img/about-us.png`} className='aboutUs__img shadow' alt='Imagen de una mujer sonriendo'/>
                </div>
            </div>
        </section>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 270"><path fill="#C39FCD" fillOpacity="1" d="M0,96L48,128C96,160,192,224,288,213.3C384,203,480,117,576,101.3C672,85,768,139,864,186.7C960,235,1056,277,1152,261.3C1248,245,1344,171,1392,133.3L1440,96L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
        <section className='purple-background'>
            <div className='container py-5 text-center'>
                <div className='col-12' data-aos="fade-up">
                    <h2 className='blue-color'>Nuestras instalaciones</h2>
                    <p className='white-color mt-5'>Tenemos las instalaciones cómodas y equipadas para brindarte el mejor servicio</p>
                    <p className='white-color fw-bold'>¡Compruébalo y agenda tu cita hoy mismo!</p>
                </div>
                <Facilities />
                <BlueButton text={'Agendar Cita'} url={'/contacto'} color={'w'} />
            </div>
        </section>
        <section className='container py-5'>
            <div className='row'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center' data-aos="fade-up">
                    <span className='flag'>RAZONES PARA ELEGIRNOS COMO</span>
                    <h2 className='text-center text-uppercase mt-3 blue-color'>Tu dentista de cabecera</h2>
                </div>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center'>
                </div>
                <KPI />
                <Reviews />
            </div>
        </section>
    </div>
  )
}

export default AboutUs;