import React, { useState, useEffect } from "react";
import NavLogo from "../../assets/logos/main-logo.png";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
import getTreatments from "../../services/getTreatments";

// import {CgMenu} from 'react-icons/cg';
// import {ImWhatsapp} from 'react-icons/im';

const Navbar = () => {
  const[allTreatments, setAllTreatments] = useState([]);

  const history = useNavigate();

  useEffect(() => {
    getAllTreatments("asc");
  }, []);

  function getAllTreatments(order) {

    let formData = new FormData();
    formData.append('order', order);

    getTreatments(formData).then((response) => {
        let _response = JSON.parse(response);
        if(_response.response === 'success') {
          setAllTreatments(_response.treatments);
        }
    });
}

  return (
    <nav className="navbar shadow header sticky-top">
      <div className="container-fluid">
        <span onClick={() => history('/')} data-bs-dismiss="offcanvas">
          <img src={NavLogo} height="55" alt="DIVUM" />
        </span>
        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="offcanvas offcanvas-end header__offcanvas" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
          <div className="offcanvas-header">
            <span onClick={() => history('/')} data-bs-dismiss="offcanvas">
              <img src={NavLogo} height="55" alt="DIVUM" />
            </span>
            <button type="button" className="btn-close btn-close-black" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body header__ul">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li className="nav-item"><button onClick={() => history('/')} className="dropdown-item" data-bs-dismiss="offcanvas">Inicio</button></li>
              <li className="nav-item"><button onClick={() => history('/nosotros')} className="dropdown-item" data-bs-dismiss="offcanvas">Nosotros</button></li>
              <li className="nav-item dropdown">
                <button className="nav-link dropdown-toggle dropdown-item" data-bs-toggle="dropdown" aria-expanded="false">Tratamientos</button>
                <ul className="dropdown-menu dropdown-menu-navbar">
                  {allTreatments?.map((treatment) => (
                    <li className="nav-item" key={treatment.id_treatment+treatment.image}>
                      <button onClick={() => history('/tratamientos/'+treatment.link)} className="dropdown-item" data-bs-dismiss="offcanvas">
                        {treatment.title}
                      </button>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-item"><button onClick={() => history('/equipo')} className="dropdown-item" data-bs-dismiss="offcanvas">Equipo</button></li>
              <li className="nav-item"><button onClick={() => history('/ubicacion')} className="dropdown-item" data-bs-dismiss="offcanvas">Ubicación</button></li>
              <li className="nav-item"><button onClick={() => history('/contacto')} className="dropdown-item" data-bs-dismiss="offcanvas">Contacto</button></li>
              {/* <li className="nav-item"><button onClick={() => history('/blog')} className="dropdown-item" data-bs-dismiss="offcanvas">Blog</button></li> */}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
