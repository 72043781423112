
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAMl5wf5QorCA3D2IfsSWExV_ah5qyY33o",
  authDomain: "div-um-storage.firebaseapp.com",
  projectId: "div-um-storage",
  storageBucket: "div-um-storage.appspot.com",
  messagingSenderId: "373770187540",
  appId: "1:373770187540:web:d7d851586c0bdc17c18801",
  measurementId: "G-3DW065ED3G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);