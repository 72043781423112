import React from 'react';
import './Facilities.css';
import OwlCarousel from "react-owl-carousel";
import config from '../../config';

const Facilities = () => {

    const facilities = [
        {id: 1, image: config.urlApi + 'assets/facilities/instalacion-1.png'},
        {id: 2, image: config.urlApi + 'assets/facilities/instalacion-2.png'},
        {id: 3, image: config.urlApi + 'assets/facilities/instalacion-3.png'},
    ];

    const state = {
        responsive: {
            0: {
                items: 2,
                center: true,
                margin: 10,
            },
            576: {
                items: 2,
                center: true,
                margin: 30
            },
            768: {
                items: 3,
                center: true,
                margin: 30
            },
            992: {
                items: 3,
                center: true,
                margin: 35
            },
            1200: {
                items: 3,
                center: true,
                margin: 35
            },
        },
    };

  return (
    <div className='reviews text-center'>
        <OwlCarousel
          className="owl-theme owl-carousel my-5"
          items="1"
          loop
          autoplay={true}
          animateIn={true}
          responsive={state.responsive}
          center={true}
        >
          {facilities.map((img) => (
            <div className="item" key={img.id}>
                <img src={img.image} alt="Facilities" className='img-fluid' />
            </div>
           ))}
        </OwlCarousel>
    </div>
  )
}

export default Facilities;