import React, { useEffect } from 'react';
import Compromise from '../components/Compromise/Compromise';
import ContactForm from '../components/ContactForm/ContactForm';
import '../components/ContactUs/ContactUs.css';
import Location from '../components/Location/Location';
import Slider from '../components/Slider/Slider';

const ContactUs = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  },[]);

  return (
    <div className='contact white-background'>
        <Slider page='contact' title='Ubicación'/>
        <ContactForm />
        <hr className='container'/>
        <Compromise />
        <Location />
    </div>
  )
}

export default ContactUs;