import React from 'react';
import './Footer.css';
import WhiteLogo from '../../assets/logos/white-logo.png';
import { Link } from 'react-router-dom';
import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='footer container-fluid blue-background'>
        <div className='container py-5'>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'>
                    <img src={WhiteLogo} alt='DIVUM Logo' className='footer__logo img-fluid' />
                    <p className='white-color fs-6 fw-lighter my-4'>10 años brindando un servicio de excelencia y estamos comprometidos por verte sonreír.</p>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <p className='white-color fs-3'>Enlaces rápidos</p>
                    <ul className='footer__ul'>
                        <li><Link to='/nosotros' className='white-color fw-lighter'>Nosotros</Link></li>
                        <li><Link to='/tratemientos' className='white-color fw-lighter'>Tratamientos</Link></li>
                        <li><Link to='/equipo' className='white-color fw-lighter'>Equipo</Link></li>
                        <li><Link to='/' className='white-color fw-lighter'>Inicio</Link></li>
                    </ul>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <p className='white-color fs-3'>Nuestros servicios</p>
                    <ul className='footer__ul'>
                        <li><Link to='/limpieza-dental' className='white-color fw-lighter'>Limpieza dental</Link></li>
                        <li><Link to='/resina-dental' className='white-color fw-lighter'>Resina dental</Link></li>
                        <li><Link to='/endodoncia' className='white-color fw-lighter'>Endodoncia</Link></li>
                        <li><Link to='/coronas-dentales' className='white-color fw-lighter'>Coronas dentales</Link></li>
                        <li><Link to='/tratamientos' className='white-color fw-lighter'>Ver todo</Link></li>
                    </ul>
                </div>
            </div>
            <hr className='white-color footer__hr'></hr>
            <div className='row'>
                <div className='col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12'>
                    <p className='white-color fw-light fs-6'>© 2023 DIVUM | Design & Developed by <a href="https://omarcamacho.dev/" className='white-color' target="_blank" rel="noreferrer">Omar Camacho</a></p>
                </div>
                <div className='col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-xl-1 mt-lg-1 mt-md-1 mt-sm-4 mt-4'>
                    <ul className='d-flex justify-content-evenly footer__social'>
                        <li><a href="https://www.facebook.com/divumclinic" target="_blank" rel="noreferrer"><FaFacebookF className='footer__icon fs-3' /></a></li>
                        <li><a href="https://www.instagram.com/divum_clinica_cosmetica/" target="_blank" rel="noreferrer"><FaInstagram className='footer__icon fs-3' /></a></li>
                        <li><a href="https://wa.me/+5215641672540?text=Hola%2C%20deseo%20conocer%20m%C3%A1s%20acerca%20de%20sus%20servicios.%20%C2%BFMe%20pueden%20ayudar%3F" target="_blank" rel="noreferrer"><FaWhatsapp className='footer__icon fs-3' /></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer;