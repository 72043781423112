import React from 'react';
import BannerPurple from '../components/BannerPurple/BannerPurple';
import Highlights from '../components/Highlights/Highlights';
import '../components/Home/Home.css';
import Reviews from '../components/Reviews/Reviews';
import Slider from '../components/Slider/Slider';
import Treatments from '../components/Treatments/Treatments';

const Home = () => {

  return (
    <div className='home white-background'>
        <Slider page={'home'}/>
        <div className='container'>
            <div className='text-center my-5'>
                <h3 className='h3__longPaddign purple-color'>Encuentra todo lo que tu sonrisa necesita en un mismo lugar</h3>
                <p className='mt-4'>10 años brindando un servicio de excelencia y estamos comprometidos por verte sonreír</p>
            </div>
            <Highlights />
            <Treatments />
        </div>
        <BannerPurple />
        <Reviews />
    </div>
  )
}

export default Home;