import React, { useState, useEffect } from 'react';
import Slider from '../components/Slider/Slider';
import '../components/TreatmentDetail/TreatmentDetail.css';
import { Link, useLocation } from 'react-router-dom';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FiChevronRight } from 'react-icons/fi';
import TreatmentDisclaimer from '../components/TreatmentDisclaimer/TreatmentDisclaimer';
import MainButton from '../components/MainButton/MainButton';
import getTreatmentDetail from '../services/getTreatmentDetail';

const TreatmentDetail = () => {
    
    const location = useLocation();
    const [treatment, setTreatment] = useState([]);
    const [benefits, setBenefits] = useState([]);
    const [list, setList] = useState([]);
    
    useEffect(() => {
        let name = location.pathname.split("/")[2];
        getDetails(name);
    }, [location.pathname]);

    function getDetails(name) {
        console.log(name)
        let formData = new FormData();
        formData.append("treatment", name);

        getTreatmentDetail(formData).then((response) => {
            let _response = JSON.parse(response);
            console.log(_response);
            if(_response.response === 'success') {
                setTreatment(_response.treatment);
                setBenefits(_response.treatment.benefits);
                setList(_response.treatment.list);
            }
        });

    }

  return !treatment || !benefits || !list ? 
   (<div className='text-center'> 
        <div class="spinner-grow text-info" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    ):(
    <div className='treatment__detail'>
        <Slider page={'treatment-detail'} />
        {/* <Slider page={'treatment-detail'} title={treatment.link}/> */}
        <div className='container my-5'>
            <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-0 sticky__side'>
                    <h2 className='text-center blue-color'>Tratamientos</h2>
                    <ul className='my-4'>
                        {list.length > 0 && list.map((item, index) => (
                            <li key={index}>
                                <Link to={`/tratamientos/${item.link}`}>{item.title} <FiChevronRight className='treatment__detail__icon-menu fs-3'/></Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12'>
                    <h2 className='text-center'>{treatment.title} te ayuda a:</h2>
                    <hr className='separator footer__hr my-4'></hr>
                    <ul>
                        {benefits.length > 0 && benefits.map((benefit, index) => (
                            <li key={index}><BsFillCheckCircleFill className='treatment__detail__icon fs-5'/> {benefit.description}</li>
                        ))}
                    </ul>
                    <h3 className='mt-5 mb-3 blue-color'>¿Qué es?</h3>
                    <p>{treatment.definicion}</p>
                    <TreatmentDisclaimer />
                    <h3 className='mt-5 mb-3 blue-color'>¿Cómo se realiza?</h3>
                    <p>{treatment.procedimiento}</p>
                    <div className='text-center mt-5' data-aos="fade-up">
                        <MainButton text={'Agendar cita'} url={'/contacto'}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TreatmentDetail;