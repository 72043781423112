import React, { useState } from "react";
import "./ContactForm.css";
import Swal from "sweetalert2";
// import addContactForm from "../../services/addContactForm";
import contactFormServices from "../../firebaseServices/contactForm.services";

const ContactForm = () => {
  const [data, setData] = useState({
    seen: false,
    date: Date.now(),
  });

  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submit = async (e) => {
    e.preventDefault();

    if (data.phone.length < 10 || data.phone.length > 10) {
      Swal.fire({
        title: "Teléfono incorrecto",
        text: "Por favor verifica que el número de teléfono sea correcto",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      try {
        await contactFormServices.addContacForm(data);
        Swal.fire({
          title: "Mensaje enviado",
          text: "En breve nos pondremos en contacto contigo",
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });

        setTimeout(() => {
          document.getElementById("name").value = "";
          document.getElementById("phone").value = "";
          document.getElementById("message").value = "";
        }, 3000);
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Error en el formulario",
          text: "Ha ocurrido un error al procesar tu formulario, inténtalo nuevamente.",
          icon: "error",
          showConfirmButton: true,
        });
      }

      // console.log(data);
      // var timestamp = Date.now();
      // console.log(new Date(timestamp));

      // var formData = new FormData();

      // formData.append('name', data.name);
      // formData.append('phone', data.phone);
      // formData.append('message', data.message);

      // addContactForm(formData).then(res => {
      //     let _response = JSON.parse(res);

      //     if(_response.response === 'success') {
      //         Swal.fire({
      //             title: 'Mensaje enviado',
      //             text: 'En breve nos pondremos en contacto contigo',
      //             icon: 'success',
      //             showConfirmButton: false,
      //             timer: 3000
      //         })

      //         setTimeout(() => {
      //             document.getElementById('name').value = "";
      //             document.getElementById('phone').value = "";
      //             document.getElementById('message').value = "";
      //         }, 3000);

      //     } else {
      //         Swal.fire({
      //             title: 'Error',
      //             text: 'Por favor verifica que todos los campos estén correctos',
      //             icon: 'error',
      //             showConfirmButton: false,
      //             timer: 3000
      //         })
      //     }
      // });
    }
  };

  return (
    <div className="contact__form container py-5">
      <div className="col-12 text-center">
        <h2 className="blue-color fs-1">Contáctanos</h2>
        <hr className="hr-blue my-4" />
        <p>
          Si tienes alguna duda por favor completa el siguiente formulario y en
          breve te responderemos.
        </p>
      </div>
      <form onSubmit={submit}>
        <div className="form-group">
          <label htmlFor="name" className="col-sm-2 col-form-label">
            Nombre
          </label>
          <input
            name="name"
            type="text"
            className="form-control shadow"
            id="name"
            placeholder="Juan Pérez"
            required
            onChange={updateData}
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone" className="col-sm-2 col-form-label">
            Teléfono
          </label>
          <input
            name="phone"
            type="text"
            className="form-control shadow"
            id="phone"
            placeholder="Teléfono"
            required
            onChange={updateData}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message" className="col-sm-2 col-form-label">
            Mensaje
          </label>
          <textarea
            name="message"
            className="form-control shadow"
            id="message"
            rows="3"
            placeholder="Mensaje"
            required
            onChange={updateData}
          ></textarea>
        </div>

        <div className="mainButton__blue text-center mt-5">
          <button className="cta" id="contact-form">
            <span className="black-color">ENVIAR MENSAJE</span>
            <svg viewBox="0 0 13 10" width="15px" stroke="#00111A">
              <path d="M1,5 L11,5"></path>
              <polyline points="8 1 12 5 8 9"></polyline>
            </svg>
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
