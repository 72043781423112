import React from 'react';
import './TreatmentDisclaimer.css';
import { GoAlert } from 'react-icons/go';

const TreatmentDisclaimer = () => {
  return (
    <div className='treatment__disclaimer shadow px-2 py-4 my-4' data-aos="fade-up">
        <div className='row d-flex align-items-center'>
            <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 text-center'>
                <GoAlert className='treatment__disclaimer__icon fs-1' />
            </div>
            <div className='col-xl-11 col-lg-11 col-md-11 col-sm-11'>
                <p className='fs-5 m-0'>Es importante la previa revisión dental de tu odontólogo, así como la consulta de los cuidados necesarios para este tratamiento, esto contribuye a una buena salud bucal.</p>
            </div>
        </div>
    </div>
  )
}

export default TreatmentDisclaimer;