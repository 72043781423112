import React, { useEffect } from "react";
import "./KPI.css";

const KPI = () => {
  useEffect(() => {
    const obj1 = document.getElementById("valueClients");
    animateValue(obj1, 0, 15, 2000);
    const obj2 = document.getElementById("valueCoverage");
    animateValue(obj2, 0, 10, 2000);
    const obj3 = document.getElementById("valueConsults");
    animateValue(obj3, 0, 50, 2000);
  }, []);

  function animateValue(obj, start, end, duration) {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      obj.innerHTML = Math.floor(progress * (end - start) + start);
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  return (
    <div className="container my-5 kpi">
      <div className="row">
        <div className="col-4 text-center" data-aos="fade-up">
          <span
            className="kpi-numbers"
            id="valueClients"
          >
            15
          </span>
          <span className="kpi-numbers">
            K+
          </span>
          <p className="font-regular">Sonrisas</p>
        </div>
        <div className="col-4 text-center" data-aos="fade-up">
          <span className="kpi-numbers"
                id="valueConsults">
            50
          </span>
          <span className="kpi-numbers">
            +
          </span>
          <p className="font-regular">Consultas diarias</p>
        </div>
        <div className="col-4 text-center" data-aos="fade-up">
          <span
            className="kpi-numbers"
            id="valueCoverage"
          >
            10
          </span>
          <span className="kpi-numbers">
            +
          </span>
          <p className="font-regular">Años de experiencia</p>
        </div>
      </div>
    </div>
  );
};

export default KPI;