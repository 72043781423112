import React from "react";
import { Link } from "react-router-dom";
import "./MainButton.css";

const MainButton = ({text,url,color}) => {
  return (
    <Link to={url}>
      <div className="mainButton__blue">
        <button className="cta">
          <span className={`${(color === 'w')? 'white-color' : 'black-color'}`}>{text}</span>
          <svg viewBox="0 0 13 10" width="15px" stroke={`${(color === 'w')? '#F2F4F5' : '#00111A'}`}>
            <path d="M1,5 L11,5"></path>
            <polyline points="8 1 12 5 8 9"></polyline>
          </svg>
        </button>
      </div>
    </Link>
  );
};

export default MainButton;