import $ from "jquery";
import config from "../config";
const URL_API = config.urlApi + "controller/getTreatmentDetail.php";

export default function getTreatmentDetail(name) {
  return $.ajax({
    url: URL_API,
    type: "POST",
    data: name,
    contentType: false,
    processData: false,
  });
}
