import React, { useEffect, useState } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import "../components/Blog/Blog.css";
import BlogSlider from "../components/BlogSlider/BlogSlider";
import config from "../config";
import getPosts from "../services/getPosts";

const Blog = () => {
  const [sliders, setSliders] = useState([]);
  const [posts, setPosts] = useState([]);

  const categories = [
    {id: 1, category: 'Odontología'},
    {id: 2, category: 'Pediatría'},
    {id: 3, category: 'Ortodoncia'},
    {id: 4, category: 'Protesis'},
    {id: 5, category: 'Alineadores'},
    {id: 6, category: 'Implantes'},
    {id: 7, category: 'Estética'},
  ]
  
  useEffect(() => {
    getSlider();
    getAllPosts();
  }, []);
  
  function getSlider() {

    let formData = new FormData();

    formData.append("option", "slider");
    formData.append("area","dental");

    getPosts(formData).then((response) => {
      let _response = JSON.parse(response);
      if (_response.response === "success") {
        setSliders(_response.posts);
      }
    });
  }

  function getAllPosts() {
    let formData = new FormData();

    formData.append("option", "all");
    formData.append("area","dental");

    getPosts(formData).then((response) => {
      let _response = JSON.parse(response);
      console.log(_response);
      if (_response.response === "success") {
        setPosts(_response.posts);
      }
    });
  }

  function formatDate(date) {
    let newDate = new Date(date);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    let months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    return `${day} ${months[month]} ${year}`;
  }
  return (
    <div className="blog white-background py-5">
      <div className="container">
        <div className="blog__header">
          <h1 className="blue-color">Blog DIVUM</h1>
          <p>Las últimas noticias en odontología, entrevistas, tecnologías y recursos.</p>
        </div>
        <BlogSlider sliders={sliders}/>
        <ul className="blog__categories my-4">
          {categories?.map((category) => (
            <li key={category.id} >
              {/* <div className="blog__category"> */}
                {category.category}
              {/* </div> */}
            </li>
          ))}
        </ul>
        <div className="row gy-4 mt-4">
          <h2 className="blue-color text-center fs-1 my-3">Resultados</h2>

          {posts?.map((post) => (
            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12 blog__card" key={"post"+post.id_post}>
              <div className="blog__card-image shadow">
                <Link to={'/blog/'+post.url} >
                  <img src={config.urlApi+'assets/posts/'+post.image} className="img-fluid" alt="imagen" />
                </Link>
                <div className="blog__card-image__content">
                  <div className="d-flex flex-column">
                    <span>{formatDate(post.date)}</span>
                    <span>{post.author}</span>
                  </div>
                </div>
              </div>
              <div className="blog__card-content mt-3">
                <Link to={'/blog/'+post.url} >
                  <h3 className="blue-color fs-5">{post.title}</h3>
                </Link>
                <p className="text-muted">{post.foreword}</p>
                <Link to={'/blog/'+post.url} className="purple-color blog__link-underline">Leer más <BsArrowUpRight className="fs-5 blog__arrow"/></Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
