import React from 'react';
import BlueButton from '../BlueButton/BlueButton';
import './BannerPurple.css';

const BannerPurple = () => {
  return (
    <div className='p-0 banner__purple'>
        <div className='container'>
            <div className='row d-flex align-items-center py-5'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12' data-aos="fade-right">
                    <h2 className='white-color fs-1'>Agenda tu cita en DIVUM</h2>
                    <p className='white-color p-0'>Porque una boca sana es una boca sin filtros. Ven a DIVUM y conoce todos los tratamientos que tenemos para mejorar tu estética dental y cuidar tu salud bucal.</p>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 text-center pt-3' data-aos="fade-left">
                    <BlueButton text='AGENDAR CITA' url='/contacto' color={'w'}/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BannerPurple;