import React, { useEffect, useState } from 'react';
import '../components/Post/post.css'
import { Link, useParams } from 'react-router-dom';
import getPost from '../services/getPost';
import { BsArrowRight } from 'react-icons/bs';
import config from '../config';
import getRandomPosts from '../services/getRandomPosts';

const Post = () => {
    
    const { url } = useParams();

    const [post, setPost] = useState([]);
    const [recommendations, setRecommendations] = useState([]);

    useEffect(() => {
        getPostByUrl(url);
        getRnmPosts();
    }, [url]);

    function formatDate(date) {
        let newDate = new Date(date);
        let day = newDate.getDate();
        let month = newDate.getMonth();
        let year = newDate.getFullYear();
        let months = [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ];
        return `${day} ${months[month]} ${year}`;
    }

    function getPostByUrl(url) {
        let formData = new FormData();

        formData.append("url", url);

        getPost(formData).then((response) => {
            let _response = JSON.parse(response);
            if (_response.response === "success") {
                setPost(_response.post);
            }
        });
    }

    function getRnmPosts() {

        const formData = new FormData();
        
        formData.append("area", "dental");

        getRandomPosts(formData).then((response) => {
            let _response = JSON.parse(response);

            if (_response.response === "success") {
                setRecommendations(_response.posts);
            }
        });
    }

  return (
    <div className='post white-background'>
        <div className='container py-5'>
            {/* <div className='post__route'> */}
                <ul className='post__route'>
                    <li><Link to={'/'} >Inicio</Link></li>
                    <li><BsArrowRight className='fs-6'/></li>
                    <li><Link to={'/blog'} >Blog</Link></li>
                    <li><BsArrowRight className='fs-6'/></li>
                    <li>{post.title}</li>
                </ul>
            {/* </div> */}
            <div className='row'>
                <div className='col-xl-9 col-lg-9 col-md-8 col-sm-12 post__container'>
                    <h1 className='my-4'>{post.title}</h1>
                    <img src={config.urlApi+'assets/posts/'+post.image} alt={post.title} className='img-fluid shadow'/>
                    <h2 className='mt-5'>Introducción</h2>
                    <p className='my-3'>{post.foreword}</p>
                </div>
                <div className='col-xl-3 col-lg-3 col-md-4 col-sm-12 post__sideBar shadow'>
                    <h3>También te puede interesar:</h3>
                    <ul className='post__sideBar__list'>
                        {recommendations?.map(post => (
                            <li key={post.id+post.url}>
                                <Link to={'/blog/'+post.url}>
                                    {post.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='col-12'>
                    <div className="mt-3 text-justify post__content" dangerouslySetInnerHTML={{ __html: post.content}}></div>
                    <div className='post__credits'>
                        <p className='m-0 blue-color'>{post.author}</p>
                        <p className='purple-color'>{formatDate(post.date)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Post;